import React, { useEffect, useState } from 'react';
import { UlWrapper, HeaderWrapper, TitleWrapper, LogoWrapper, TitleWrappers, SloganWrapper } from './wrappers.ts';
import { useLocation, useNavigate } from 'react-router-dom';
import { mobile } from '../../assets/devices/index.tsx';
import { TemporaryDrawer } from '../Drawer/index.tsx'; 
import { getToken } from '../../utils/authHelpers.ts';
import { AuthDialog } from '../../components/AuthDialog/index.tsx';

const pages = ['/', '/about', '/blog'];

export const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const token = getToken()

    const [isMobile, setIsMobile] = useState(window.innerWidth <= mobile); // Set initial value
    const [open, setOpen] = React.useState(false);

    const handleClick = (i) => {
        navigate(pages[i]);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= mobile);
        };
        window.addEventListener('resize', handleResize);
        
        return () => {
            // Clean up the event listener on unmount
            window.removeEventListener('resize', handleResize);
        };
    }, [location.pathname]);

    const handleAuthAction = () => {
        if (token) {
            // Handle logout
            navigate('/profile'); // Redirect to home or any other page
        } else {
            // Open sign-up dialog
            setOpen(true)
        }
    };

    if (isMobile) {
        return <TemporaryDrawer />;
    } else {
        return (
            <UlWrapper>
                <HeaderWrapper>
                    <LogoWrapper>
                    <h1>The Colivings</h1>
                    </LogoWrapper>
                    <SloganWrapper>Find Your Ideal Coliving Community</SloganWrapper>
                    <TitleWrappers>
                        <TitleWrapper onClick={() => handleClick(0)} >
                            Search
                        </TitleWrapper>
                        <TitleWrapper onClick={() => handleClick(1)} >
                            About
                        </TitleWrapper>
                        <TitleWrapper onClick={() => handleClick(2)} >
                            Blogs
                        </TitleWrapper>
                        <TitleWrapper onClick={handleAuthAction} >
                        {token ? 'Profile' : 'Log in'}                        
                        </TitleWrapper>
                    </TitleWrappers>
                    <AuthDialog open={open} setOpen={setOpen}/>
                </HeaderWrapper>
            </UlWrapper>
        );
    }
};
