import React from "react";
import { RoomContainer, ImageContainer } from "./wrappers.tsx";

export const Room = ({name, description, price, image, alt, discountThreeWeeks, discountMonth, discountTwoMonths}) => {
  return (
    <RoomContainer>
      <h3>{name}</h3>
      <p>{description}</p>
      <ImageContainer>
      {image.map((imageUrl, index) => (
        <img key={index} src={imageUrl} alt={alt} />
      ))}        
      </ImageContainer>
      <p>price: {price} €/day</p>
      {
        discountThreeWeeks && 
        <>
          <h4>discounts by long stay:</h4>
          <p>3 week: {discountThreeWeeks}</p>
          <p>1 month: {discountMonth}</p>
          <p>2 months: {discountTwoMonths}</p>
        </>
      }

    </RoomContainer>
  );
}