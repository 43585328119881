import styled from 'styled-components';
import { colors } from '../../assets/colors/index.tsx'

export const Container = styled.div`
    text-align: justify;
    padding-top: 2em;
    & h3 {
    margin: 1em 0;
    text-align: start;
    }
`

export const ContactWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction:column;
    margin: 1em 0;
    & h1 {
    margin: 1em 0;
    }
`

export const TextWrapper = styled.div`
    display: flex;
    margin-left: 3em;
    flex-direction: column;
    text-align: justify;
    & h2 {
        text-align: center;
        margin-top: 0.5em;
    }
`

export const ProfileWrapper = styled.div`
    display: flex;
`

export const ProfilesWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

export const RightContactWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 1em;
`

export const ButtonWrapper = styled.button`
    background-color: ${colors.primary};
    color: ${colors.tertiary};
    padding: 1.5em;
    border-radius: 5px;
    border: 2px solid #E6E6E6;
    margin-top: 1em;
`

export const ValuesWrapper = styled.div`
    display:flex;
    justify-content: space-between;
`

export const ValueWrapper = styled.div`
    padding: 0 1em;
`

export const InputWrapper = styled.input`
    padding: 1em;
    border-radius: 5px;
    border: 2px solid #E6E6E6;
    margin: 0.5em 0;
`

export const TextareaWrapper = styled.textarea`
    padding: 1em;
    height: 8em;
    border-radius: 5px;
    border: 1px solid #E6E6E6;
`