import React, { useState } from "react"
import { ContactWrapper, Container, RightContactWrapper, TextareaWrapper, InputWrapper, ButtonWrapper } from './wrappers.tsx'
import emailjs from 'emailjs-com';

export const About = () => {
    const validateEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');

    const handleNameChange = (e) => {
        setName(e.target.value);
    };
    
    const handleEmailChange = (e) => {
    setEmail(e.target.value);
    };

    const handleMessageChange = (e) => {
    setMessage(e.target.value);
    };
    
    const handleSubjectChange = (e) => {
    setSubject(e.target.value);
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateEmail(email)) {
            console.log('Invalid email');
            return;
        }
        const templateParams = {
            from_name: name,
            from_email: email,
            message: message,
            subject: subject
        };
        emailjs
            .send(
                process.env.REACT_APP_EMAIL_API_KEY || '',
                process.env.REACT_APP_EMAIL_TEMPLATE_KEY || '',
                templateParams,
                process.env.REACT_APP_EMAIL_PUBLIC_KEY || ''
            )
            .then((response) => {
                console.log('Email sent successfully!', response);
                // TODO: Show success message or perform any other actions
            })
            .catch((error) => {
                console.error('Error sending email:', error);
                // TODO: Show error message or perform any other actions
            });
            // Reset form fields
            setName('');
            setEmail('');
            setSubject('')
            setMessage('');
    };

    return (
        <Container>
            <h1>About us</h1>
            <h3>What is The Colivings?</h3>
            <p>The colivings is a platform of colivings where you can search and book colivings all over the world.</p>
            <p>Through shared experiences, from family dinners to outdoor adventures, we're reshaping the digital nomad experience by providing a global platform</p>
            <p>that seamlessly connects them with trusted coliving spaces, fostering cultural exploration and worldwide connections.</p>
            <h3>What is a Coliving?</h3>
            <p>Coliving is a vibrant and community-focused living arrangement that goes beyond just sharing space. It's a lifestyle choice embraced by individuals who value the sense of belonging and connection with others. In a coliving setting, residents come together to create a close-knit community, fostering meaningful relationships and shared experiences.
            Imagine living in a space where you not only have your own private quarters but also share common areas like kitchens, living rooms, and communal spaces with like-minded individuals. It's more than just a place to stay; it's an opportunity to build lasting friendships, collaborate on projects, and support each other's personal and professional growth.
            Whether you're an aspiring artist, entrepreneur, or simply someone who thrives in a social environment, coliving offers a platform to connect, collaborate, and create. You'll find yourself in the company of diverse individuals with unique backgrounds and talents, all contributing to the vibrant tapestry of your coliving community.
            The emphasis in coliving is on shared values, experiences, and a commitment to making everyday life richer through communal living. It's a space where everyone plays a part in shaping the community, where your neighbors become your friends, and where you can truly experience the joys of living together.</p>
            <h3>What are the benefits of Coliving?</h3>
            <p>
            Coliving is like a modern twist on traditional roommates, but it's so much more. It's about forming a tight-knit community where you live, work, and grow together. You share expenses, which makes it budget-friendly, and you often get access to cool amenities. It's a fantastic way to meet diverse, interesting people and build lasting friendships. Plus, it can be a springboard for personal and professional growth. It's like having a built-in support system and a fun, vibrant lifestyle all rolled into one.
            </p>
            <h3>How does co-living differ from traditional renting?</h3>
            <p>
            Co-living feels like choosing a fun, supportive family over solo renting. You share your space, expenses, and life moments with a diverse group of friends. It's about community, shared experiences, and less isolation. In traditional renting, it's more independent, often lonelier, and less communal.
            There's no hidden costs, the payment covers all the expendes, such as gas, electricity, cleaning. It's build so you can be productive and work and have fun, without the worries of renting.
            </p>
            <h3>What amenities are typically included in a co-living space?</h3>
            <p>
            Co-living spaces usually offer a bundle of conveniences: furnished rooms, cleaning services, Wi-Fi, and often social areas like lounges and kitchens. It's like a ready-made, comfy home where you can focus on living and connecting with others.
            </p>
            <h3>What is the duration of a co-living agreement? Can I rent on a short-term basis? </h3>
            <p>
                Co-living agreements vary, but many offer flexible lease terms, including short-term options. It's like tailor-made renting to suit your needs. Althought most of them have a minimum of 15 days stay. So you could fully inmerse in the experience.
            </p>
            <h3>What is the community like in a co-living space? How is it maintained?</h3>
            <p>Co-living fosters a close-knit community through shared spaces and activities. Maintenance often involves regular events, shared responsibilities, and a sense of belonging. It's like living among friends who help create a vibrant atmosphere.</p>
            <h3>What are the rules and policies regarding guests and visitors?</h3>
            <p>Co-living spaces usually have guest policies in place to ensure security and comfort for all residents. Guests are typically allowed but may need to be registered or have restricted access. These rules aim to strike a balance between fostering community and respecting residents' privacy and safety. 
            </p>
            <ContactWrapper>
                <h1>Contact us</h1>
                <p>Do you have a coliving space you'd like to feature here? </p>
                <p>We're dedicated to showcasing all the options available to digital nomads </p>
                <p>for a complete experience. Let's connect!</p>
                
                <form onSubmit={handleSubmit}>
                    <RightContactWrapper>
                            <InputWrapper
                            type="text"
                            id="name"
                            placeholder='Name'
                            value={name}
                            onChange={handleNameChange}
                            />
                            <InputWrapper
                            type="email"
                            id="email"
                            placeholder='Email'
                            value={email}
                            onChange={handleEmailChange}
                            />
                            <InputWrapper
                            type="text"
                            id="subject"
                            value={subject}
                            placeholder='Subject'
                            onChange={handleSubjectChange}
                            />
                            <TextareaWrapper
                            id="message"
                            value={message}
                            onChange={handleMessageChange}
                            placeholder='Message'
                            /> 
                            <ButtonWrapper type="submit">Send Email</ButtonWrapper>
                    </RightContactWrapper>
                </form>
            </ContactWrapper>
        </Container>
    )
}