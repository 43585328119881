import styled from 'styled-components';
import { colors } from '../../assets/colors/index.tsx'

export const PageWrapper = styled.div`
  background: ${colors.secondary};
  padding-top: 2em;
  text-align: justify;
  & h3 {
    margin: 1em 0;
  }
`
export const TitleWrapper = styled.h1`
  line-height: 1.25em;
  margin-bottom: 1em;
  text-align: start;
`

export const InputWrapper = styled.input`
  padding: 1em;
  width: 25em;
  border-radius: 5px;
  border: 2px solid #E6E6E6;
`

export const ButtonWrapper = styled.button`
  background-color: ${colors.primary};
  color: ${colors.tertiary};
  padding: 1em;
  border-radius: 5px;
  border: 2px solid #E6E6E6;
  margin-left: 2em;
`
export const NewsletterWrapper = styled.div`
  display: flex;
`

export const ImageWrapper = styled.img`
  width: 100%;
  margin: 2em auto;
  display: block; 
  border-radius: 5px;
`