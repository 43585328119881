import React from "react";
import { NotFoundWrapper } from "./wrappers.tsx";

export const NotFound = () => {
  return (
    <NotFoundWrapper>
      <h1>Not found</h1>
      <p>The page you are looking for does not exist.</p>
    </NotFoundWrapper>
  );
}