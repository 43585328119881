import styled from 'styled-components';
import { device } from '../assets/devices/index.tsx';
import { colors } from '../assets/colors/index.tsx'

export const GeneralWrapper = styled.div`
  padding: 0 2em;  
  @media ${device.laptop} {
    padding: 0 7em;
  }
  background: ${colors.secondary};
`