import styled from 'styled-components';
import { colors } from '../../assets/colors/index.tsx';

export const UlWrapper = styled.ul`
  list-style-type: none;
  align-items: center;
  padding: 2em 7em 0 7em;
`;

export const LogoWrapper = styled.div`
  color: black;
  margin-right: 3em;
  font-weight: bolder;
  & img {
    width: 50px;
  }
`;

export const SloganWrapper = styled.div`
  color: black;
  border-left: 1px solid ${colors.tertiary};
  align-items: center;
  display: flex;
  padding: 1em 4em 1em 1em;
`

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  color: black;
  align-items: center;
  display: flex;
  padding: 1em 4em 1em 1em;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    background-color: ${colors.primary}
  }
`;

export const TitleWrappers = styled.div`
  display: flex;
  margin-left: auto;
`;
