import axios from "axios";

export const getToken = () => localStorage.getItem('token');
export const removeToken = () => localStorage.removeItem('token');

export const handleAuthentication = (token) => {
  // Store the token in localStorage or sessionStorage
  localStorage.setItem('token', token);
  // Apply the token to axios headers
  setAuthToken(token);
};

  // Function to set the token in axios headers
const setAuthToken = (token) => {
  if (token) {
    // Apply the token to every request header
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    // Remove the token from the header
    delete axios.defaults.headers.common['Authorization'];
  }
};