import React, { useEffect, useState } from "react";
import { Container, CalendarWrapper, LogoWrapper, ColivingNameWrapper, SocialNetworkLogosWrapper, SocialNetworkContainer, FormWrapper } from "./wrappers.ts";
import { ImageGallery } from "../../components/ImageGallery/index.tsx";
import { Calendar } from "../../components/Calendar/index.tsx";
import dayjs from 'dayjs';
import { ButtonWrapper, InputWrapper, TextareaWrapper } from "../About/wrappers.tsx";
import emailjs from 'emailjs-com';
import VideoPlayer from "../../components/VideoPlayers/index.tsx";
import colivingMadrid from '../../images/Co-living-Madrid-logo.png'
import logoInstagram from '../../images/iglogo.png'
import slackLogo from '../../images/slacklogo.png'
import googlephotoslogo from '../../images/googlephotoslogo.png'
import { images, mockedRooms } from '../../mocks/colivings/coliving-madrid/index.tsx';
import { Room } from "../../components/Room/index.tsx";
import { Reviews } from "../../components/Reviews/index.tsx";
import { getColiving } from "../../services/coliving/index.ts";

//id of coliving madrid
const colivingId = '655b635f38d5fb1e76cbf4b6'

export const Coliving = () => {
  const [checkinDate, setCheckinDate] = useState(null);
  const [checkoutDate, setCheckoutDate] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('booking');
  const [isSubmitDone, setIsSubmitDone] = useState(false)
  const [coliving, setColiving] = useState([]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
  setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
  setMessage(e.target.value);
  };
  
  const today = dayjs();

  const handleCheckinChange = (newDate) => {
    setCheckinDate(newDate);
  };

  const handleCheckoutChange = (newDate) => {
    setCheckoutDate(newDate);
  };
  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const HandleBookAgain = () => {
    setIsSubmitDone(false)
  }

  const isInvalidStay = checkoutDate && checkinDate && checkoutDate.diff(checkinDate, 'day') < 15;

  useEffect(() => {
    const fetchColiving = async () => {
      try {
        const fetchColiving = await getColiving(colivingId);
        setColiving(fetchColiving);
      } catch (error) {
        console.error("Failed to fetch reviews:", error);
      }
    };
    fetchColiving();
  }, []); 

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
        console.log('Invalid email');
        return;
    }
    const templateParams = {
        from_name: name,
        from_email: email,
        message: message,
        subject: subject,
        checkinDate,
        checkoutDate
      };
      emailjs
      .send(process.env.REACT_APP_EMAIL_API_KEY, process.env.REACT_APP_EMAIL_TEMPLATE_KEY, templateParams, process.env.REACT_APP_EMAIL_PUBLIC_KEY)
      .then((response) => {
        console.log('Email sent successfully!', response);
        // TODO: Show success message or perform any other actions
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        // TODO: Show error message or perform any other actions
      });
        // Reset form fields
        setName('');
        setEmail('');
        setMessage('');
        setSubject('');
        setCheckinDate(null)
        setCheckoutDate(null)
        setIsSubmitDone(true)
  };

  return (
    <Container>
      <ColivingNameWrapper>
        <LogoWrapper src={colivingMadrid} alt="logo-coliving-madrid" />
        <h1>{coliving.name}</h1>
      </ColivingNameWrapper>
      <p>Nestled in the heart of Spain, Coliving Madrid offers an immersive experience that seamlessly blends community, comfort, and culture. Our spaces are designed to be more than 
      just living quarters – they're vibrant hubs where like-minded individuals come together to create lasting connections.</p>
      <ImageGallery images={images}/>
      <p>From beautifully furnished common areas that encourage interaction to cozy private rooms that provide a personal sanctuary, every detail has been meticulously curated to foster a sense of belonging.</p>
      <p>In Coliving Madrid, you'll discover a dynamic environment that embraces both work and play. Whether you're a digital nomad, an artist seeking inspiration, or a traveler exploring new horizons, our space caters to diverse lifestyles.</p>
      <p>Engage in skill-sharing workshops, unwind in communal lounges, or venture out to explore the rich tapestry of Madrid's offerings. With the city's vibrant neighborhoods, historic landmarks, and culinary delights at your doorstep, Coliving Madrid is more than a living space – it's a vibrant lifestyle that encourages exploration, growth, and meaningful connections.</p>
      {mockedRooms.map((room, index) => (
        <Room
          key={index}
          name={room.name}
          description={room.description}
          price={room.price}
          image={room.image}
          alt={room.alt}
          discountMonth={room.discountMonth}
          discountThreeWeeks={room.discountThreeWeeks}
          discountTwoMonths={room.discountTwoMonths}
        />
      ))}
      <CalendarWrapper>
        <Calendar label='Check in' onChange={handleCheckinChange} minDate={today} value={undefined} sx={{'z-index': '0'}} />
        <Calendar label='Check out' onChange={handleCheckoutChange} minDate={checkinDate} value={undefined} sx={{'z-index': '0'}}/>
      </CalendarWrapper>
      <Reviews reviews={coliving.reviews}/>
      {isInvalidStay && <p style={{ color: 'red' }}>Minimum stay is 15 days</p>}
      {checkinDate && checkoutDate &&!isInvalidStay && 
      <FormWrapper onSubmit={handleSubmit}>
        <InputWrapper
        type="text"
        id="name"
        placeholder='Name'
        value={name}
        onChange={handleNameChange}
        />
        <InputWrapper
        type="email"
        id="email"
        placeholder='Email'
        value={email}
        onChange={handleEmailChange}
        />
        <TextareaWrapper
        id="message"
        value={message}
        onChange={handleMessageChange}
        placeholder='Message'
        />
        <ButtonWrapper type="submit">Send Email</ButtonWrapper>
      </FormWrapper>
      }
      {isSubmitDone && 
        <>
        <p>booking done, check your email :)</p>
        <ButtonWrapper onClick={HandleBookAgain}>Book again</ButtonWrapper>
        </>
      }
        <h3 style={{marginBottom:'1em'}}>Blogs of Coliving Madrid</h3>
        <a href="https://travelandtapas.com/coliving-spain/madrid/" rel="follow">Travel and tapas blog on our coliving</a>
        <p></p>
        <a href="https://travelandtapas.com/coliving-spaces-spain/coliving-madrid/" rel="follow">Travel and tapas  second blog of our coliving </a>
        <p></p>
        <a href="https://ebom.es/casas/la-casa-de-lu-un-coliving-en-rivas/" rel="follow">Ebom magazine blog on our coliving</a>
        <p></p>
      <h3>Our videos</h3>
      <VideoPlayer
        src={`39pjZYZoXPk`}
        title="Coliving Madrid jam"
      />
      <VideoPlayer
        src={`DhU4H5ofWmc`}
        title="pingpong at coliving madrid"
      />
      <VideoPlayer
        src={`CzcfKM6w80E`}
        title="Dance, Connect, and Live: Embracing the Rythm of Coliving"
      />
      <VideoPlayer
        src={`UuiI9sqLlzA`}
        title="The mountains remind us that there is a bigger world beyond the screen⛰️🪵☁️☀️"
      />
      <h2>Our social networks</h2>
      <SocialNetworkContainer>
        <SocialNetworkLogosWrapper href="https://www.instagram.com/colivingmadrid/">
          <img src={logoInstagram} alt="Instagram Logo" />
        </SocialNetworkLogosWrapper>
        <SocialNetworkLogosWrapper href="https://app.slack.com/client/T055G987VL5/C0568U0TSF2">
          <img src={slackLogo} alt="Slack Logo" />
        </SocialNetworkLogosWrapper>
        <SocialNetworkLogosWrapper href="https://photos.app.goo.gl/b7E4A3ECHbVCp3xy5"> 
          <img src={googlephotoslogo} alt="Slack Logo" />
        </SocialNetworkLogosWrapper>
      </SocialNetworkContainer>
    </Container>
  );
}
