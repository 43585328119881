import React from 'react';
import '@justinribeiro/lite-youtube';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'lite-youtube': any; // Using 'any' type to bypass type checking
    }
  }
}

const VideoPlayer = ({ src, title}) => {
  return (
      <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
        <lite-youtube 
          videoid={src}
          videotitle={title}
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 'none', margin: '2em 0'}}         
          posterquality="maxresdefault"
          loading='lazy'
          short
        >
        </lite-youtube>
      </div>
  );
};

export default VideoPlayer;
