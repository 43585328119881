import React from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const Calendar = ({ label, onChange, minDate, value, sx }) => {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value}
        minDate={minDate} // Use minDate if provided, otherwise use today's date
        onChange={onChange}
        sx={sx}
      />
    </LocalizationProvider>
  );
}
