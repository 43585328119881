import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FooterWrapper, ListWrapper, ListsWrapper } from "./wrapper.tsx";
import { footerFAQLinks, footerLinks } from "../../mocks/footerlinks/index.ts";
import { mobile } from "../../assets/devices/index.tsx";

export const Footer = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= mobile); // Set initial value


  useEffect(() => {

    const handleResize = () => {
      setIsMobile(window.innerWidth <= mobile);
    };
    window.addEventListener('resize', handleResize);
    
    return () => {
        // Clean up the event listener on unmount
        window.removeEventListener('resize', handleResize);
    };

  })
    
  if (isMobile) {
    return null; // Don't render the footer on mobile
  }

  return (
    <FooterWrapper>
      <div>
        <h3>Location</h3>
        {footerLinks.map((link) => (
          <ListsWrapper key={link.id}>
            <ListWrapper>
              <Link to={link.url}>{link.title}</Link>
            </ListWrapper>
          </ListsWrapper>
        ))}
      </div>
      <div>
        <h3>Q&A</h3>
        {footerFAQLinks.map((link) => (
          <ListsWrapper key={link.id}>
            <ListWrapper>
              <Link to={`${link.url}`}>{link.title}</Link>
            </ListWrapper>
          </ListsWrapper>
        ))}
      </div>
    </FooterWrapper>
  );
};