import styled from 'styled-components';
import { colors } from '../../assets/colors/index.tsx'

export const WordsWrapper = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    overflow: hidden;
    color: ${props => props.c || colors.quaternary};
    background: ${props => props.b || colors.secondary};
    padding-bottom: 1em;
`

export const WordWrapper = styled.div`
    padding: 0.5em 1em;
    font-size: 1.25em;
`

export const FooterWrapper = styled.div`
    margin-top: 2em;
    padding: 0 7em 2em 7em;
    display: flex;
    justify-content: space-between;
`

export const ListWrapper = styled.ul`
    padding:0 1em 0 0;
    & li{
        list-style: none;
    }
    & a {
        color: ${colors.quaternary};
    }
    line-height: 2em;
`

export const ListsWrapper = styled.div`
    display: flex;
`