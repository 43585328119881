import styled from 'styled-components';
import { colors } from '../../assets/colors/index.tsx';

export const ButtonWrapper = styled.div`
    background-color: ${props => props.selected ? colors.primary : colors.quinary};
    color: ${props => props.selected ? colors.tertiary : colors.quaternary};
    padding: 0.5em 1em;
    border-radius: 15px;
    border: 1px solid #ECECEC;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 1em;
`