import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CloseIcon from '@mui/icons-material/Close';
import { colors } from "../../assets/colors/index.tsx";
import ContainerWrapper, { ImageWrapper } from "./wrappers.ts";

export const ImageGallery = ({images}) => {
  const [carouselOpen, setCarouselOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  useEffect(() => {
    // Add a class to the body to disable scroll when the modal is open
    if (carouselOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      // Cleanup: Remove the class when the component unmounts
      document.body.style.overflow = "auto";
    };
  }, [carouselOpen]);

  const openCarousel = (index) => {
    setSelectedImageIndex(index);
    setCarouselOpen(true);
  };

  const closeCarousel = () => {
    setSelectedImageIndex(0); // Reset selected image index
    setCarouselOpen(false);
  };

  return (
    <Box sx={{ width: "100%", overflowY: "scroll" }}>
      {!carouselOpen && (
        <ImageList
          variant="masonry"
          cols={3}
          gap={8}
          sx={{
            maxWidth: "100%", 
          }}
        >
          {images.map((item, index) => (
            <ImageListItem key={item.img} onClick={() => openCarousel(index)}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
      {carouselOpen && (
        <ContainerWrapper
        >
          <button
            className="close-button"
            onClick={closeCarousel}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 1000,
              borderRadius: "5px",
              backgroundColor: "rgba(255, 255, 255, 0.35)",
              border: "none",
            }}
          >
            <CloseIcon style={{ color: colors.primary  }}/>
          </button>
          <Carousel
            selectedItem={selectedImageIndex}
            showArrows={true}
            showThumbs={false}
            dynamicHeight={true}
          >
            {images.map((item) => (
              <div key={item.img} style={{ position: "relative" }}>
                <ImageWrapper
                  src={item.img}
                  alt={item.title}
                  loading="lazy"
                  style={{ maxHeight: "80vh" }}
                />
              </div>
            ))}
          </Carousel>
        </ContainerWrapper>
      )}
    </Box>
  );
};
