import styled from 'styled-components';

const ContainerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
  }
`;

const ImageWrapper = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  
  @media (min-width: 768px) {
    max-height: 80vh;
  }

`;

export { ContainerWrapper, ImageWrapper };

export default ContainerWrapper;
