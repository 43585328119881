export const colors = {
  primary: '#FFBE98',
  secondary: '#FFF',
  tertiary: '#877091',
  quaternary: '#2f4858',
  quinary: '#ECECEC'
}

// #e69c9b
// #bb839c
// #877091
// #555d79