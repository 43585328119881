import styled from 'styled-components';
import { colors } from '../../assets/colors/index.tsx';

export const Container = styled.div`
  background: ${colors.secondary};
  margin: 0;
  padding-top: 1em;
  position: sticky;
  top: 0;
  z-index: 1; /* Ensure the sticky element is above the other content */
`;

export const TitleWrapper = styled.div`
  display: flex;
  margin: 0 1em;
  color: ${colors.primary};
  justify-content: center;
  align-items: center;
  & img {
    width: 75px;
  }
  & h1 {
    margin-right: auto;
  }
`;