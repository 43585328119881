import styled from 'styled-components';
import { colors } from '../../assets/colors/index.tsx';
import { device } from '../../assets/devices/index.tsx';

export const Container = styled.div`
    padding-top: 2em;
`

export const BodyWrapper = styled.div`
    padding: 3em 5em;
    background: #F8F7F7;
    `

export const ButtonContainer = styled.div`
    margin: 2em 0;
    display: flex;
    overflow-x: auto;
    & :first-child {
        margin-left: 0;
    }
    & :last-child {
        margin-right: 0;
    }
`

export const CardsandMapsWrapper = styled.div`
    display: flex;
    `

export const ColivingsWrapper = styled.div`
    display: flex;
    cursor: pointer;
    flex-wrap: wrap;
    margin: 0 0 2em 0;
`

export const ColivingWrapper = styled.div`
    display:flex;
    flex-direction:column;
    border-radius: 5px;
    margin: 0em 1em 1em 0em;    
    background: white; 
    @media ${device.laptop} {
        width: 280px;
    }
`

export const ImageWrapper = styled.img`
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 100%;
    max-width: 100%;
    height: 200px;
    width: 100%;
    object-fit: cover;
`

export const ParagraphWrapper = styled.div`
    padding: 0.15em 0 0 1em;
    font-size: 0.75em;
    & p:first-child {
        font-size: 1.5em;
        margin:0;
        font-weight: bold;
    }
    & p:last-child {
        margin: 0.15em 0 1em 0;
    }
`

export const NoColivingWrapper = styled.div`
    width: 1100px;
`

export const CustomButtonWrapper = styled.button`
    height: 44px;
    margin: 0.5em auto;
    width: 44px;
    height: 44px;
    background: ${colors.primary};
    border: 2px solid ${colors.primary};
    box-shadow: 0px 0px 9px rgba(104, 104, 104, 0.22);
    border-radius: 31px;
    color: white;
    font-size: 1.5em;
`