import styled from 'styled-components';
// import { colors } from '../../assets/colors/index.tsx';

export const RoomContainer = styled.div`
  & img {
    width: 100%,
    height: auto;
    margin-bottom: 0.5em;
  }
` 

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
`