import React from 'react';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import { Container, StyledButton, StyledForm } from './wrappers.ts';
import { useAuthForm } from '../../hooks/useAuthForm.ts';

export const SignUp = () => {
  const initialFormState = {
    name: '',
    email: '',
    password: '',
    passwordConfirm: '',
  };
  const { formData, errorMessage, handleChange, handleSubmit, goTo, isError } = useAuthForm(
    initialFormState,
    'signup'
  );

  return (
    <Container>
      <StyledForm onSubmit={handleSubmit}>
        <h1>Sign Up</h1>
        <TextField
          type="text"
          name="name"
          label="Name"
          value={formData.name}
          onChange={handleChange}
          variant="standard"          
          error={isError}
        />
        <TextField
          type="email"
          name="email"
          label="Email"
          value={formData.email}
          onChange={handleChange}
          variant="standard"
          error={isError}
        />
        <TextField
          type="password"
          name="password"
          label="Password"
          value={formData.password}
          onChange={handleChange}
          variant="standard"
          error={isError}
        />
        <TextField
          type="password"
          name="passwordConfirm"
          label="Confirm password"
          value={formData.passwordConfirm}
          onChange={handleChange}
          variant="standard"
          error={isError}
        />
        <StyledButton type="submit">Sign Up</StyledButton>
        <Button color="secondary" onClick={() => goTo('/login')}>
          Login
        </Button>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      </StyledForm>
    </Container>
  );
};

