import { api } from "../api.ts";

export const signUp = async (formData) => {
  try {
    const response = await api.post('users/signup', formData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const login = async (formData) => {
  try {
    const response = await api.post('users/login', formData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getProfile = async (token) => {
  const response = await api.get('users/me', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
};

export const updateProfile = async (token, formData) => {
  const response = await api.patch('users/updateMe', formData, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
};
