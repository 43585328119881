import styled from 'styled-components';

export const Container = styled.div`
  margin: 1em 0;
` 

export const ReviewWrapper = styled.div`
  border: 1px solid #ccc; 
  padding: 1em; 
  margin-bottom: 1em; 
  border-radius: 5px; 
`