import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SearchIcon from '@mui/icons-material/Search';
import SailingIcon from '@mui/icons-material/Sailing';
import CottageIcon from '@mui/icons-material/Cottage';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import { Container, TitleWrapper } from './wrappers.ts';
import { colors } from '../../assets/colors/index.tsx';
import { getToken } from '../../utils/authHelpers.ts';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export const TemporaryDrawer = () => {
  const [state, setState] = React.useState({
    left: false,
  });

  const navigate = useNavigate();
  const token = getToken();

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {[
          { text: 'Find', path: '/', icon: <SearchIcon style={{ color: colors.primary }} /> },
          { text: 'About', path: '/about', icon: <CottageIcon style={{ color: colors.primary }} /> },
          { text: 'Blogs', path: '/blog', icon: <SailingIcon style={{ color: colors.primary }} /> },
          { text: 'Q&A', path: '/questions-and-answers', icon: <QuestionAnswerIcon style={{ color: colors.primary }} /> },
          { text: token ? 'Profile' : 'Login', path: token ? '/profile' : '/login', icon: <PersonIcon style={{ color: colors.primary }} /> },
        ].map(({ text, path, icon }) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText
                primary={text}
                onClick={() => navigate(path)}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Container>
      {(['left'] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <TitleWrapper>
            <Button onClick={toggleDrawer(anchor, true)}>
              <MenuRoundedIcon fontSize='large' style={{ color: colors.primary }} />
            </Button>
            <h1>The Colivings</h1>
          </TitleWrapper>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </Container>
  );
};
