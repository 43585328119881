import React, {useState, useEffect} from 'react';
import { ColivingWrapper, ButtonContainer, ImageWrapper, ColivingsWrapper, ParagraphWrapper, CardsandMapsWrapper, NoColivingWrapper, CustomButtonWrapper, Container } from "./wrappers.tsx"
import { colivings } from '../../mocks/colivings/index.ts'
import Button  from '../../components/Button/index.tsx'
import InfiniteScroll from 'react-infinite-scroll-component';
import {footerLinks} from '../../mocks/footerlinks/index.ts'
import { useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';

const pages = ["/spain", "/france", "/italy", '/portugal', '/germany'];

export const Find = () => {
    const [colivingsList, setColivingsList ] = useState(colivings)
    const [searchInput, setSearchInput] = useState('')
    const [country, setCountry] = useState('')
    const [selectedButton, setSelectedButton] = useState(null);
    const navigate = useNavigate()

    const scrollToTop = () => {
        const div = document.getElementsByClassName('infinite-scroll-component')[0];
        div.scrollTop = 0;
    }

    const filter = (arr, fn) => {
        let newArr = [];
        for (let i = 0; i < arr.length; i++) {
            if (fn(arr[i])) {
            newArr.push(arr[i]);
            }
        }
        return newArr;
    };

    useEffect(() => {
        if (searchInput.length > 0) {
            const filteredColivings = filter(colivingsList, (coliving) => JSON.stringify(coliving).toLowerCase().includes(searchInput.toLowerCase()));
            setColivingsList(filteredColivings)
        } 
        else {
            setColivingsList(colivings)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput]);

    useEffect(() => {
        let filteredColivings = []
        setCountry(window.location.pathname.split('/')[1])
        if (country) {
            setSearchInput(country)
            filteredColivings = filter(colivingsList, (coliving) => JSON.stringify(coliving).toLowerCase().includes(country.toLowerCase()));
            setColivingsList(filteredColivings)
        }
        else {
            setColivingsList(colivings)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country]);

    const handleButtons = (id) => {        
        setSelectedButton(id);
        navigate(pages[id]);
        setColivingsList(colivings)
        setCountry(window.location.pathname.split('/')[1])
    }

    return (
        <Container>
            <TextField 
            variant='standard' 
            type="text" 
            id="placeFilter" 
            label="Where to?" 
            value={searchInput} 
            onChange={e => setSearchInput(e.target.value)} 
            />
            <ButtonContainer>
                {footerLinks.map((button, id) => (
                    <Button 
                    key={id}
                    selected={ id === selectedButton}
                    label={button.label}
                    onClick={() => handleButtons(id)}
                    />
                ))}
            </ButtonContainer>
            <CardsandMapsWrapper>
                {colivingsList.length ? (
                    <InfiniteScroll
                        dataLength={colivingsList.length}
                        next={colivingsList.fetchMoreData}
                        hasMore={colivingsList.hasMore}
                        loader={<h4>Loading...</h4>}
                        height={620}
                    >
                        <ColivingsWrapper>
                            {colivingsList.map((coliving, i) => (
                                <ColivingWrapper key={i} onClick={() => window.location.href = coliving.url}>
                                    <ImageWrapper src={coliving.images} alt={coliving.name} />
                                    <ParagraphWrapper>
                                        <p>{coliving.name}</p>
                                        <p>{coliving.adress}</p>
                                    </ParagraphWrapper>
                                </ColivingWrapper>
                            ))}
                            <CustomButtonWrapper onClick={scrollToTop}>↑</CustomButtonWrapper>
                        </ColivingsWrapper>
                    </InfiniteScroll>
                ) : (
                    <NoColivingWrapper>no colivings matching your search</NoColivingWrapper>
                )}
            </CardsandMapsWrapper>
        </Container>
    )
}

