import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getProfile, updateProfile } from '../services/auth/index.ts';
import { getToken, removeToken } from '../utils/authHelpers.ts';

export const useUserProfile = () => {
  const navigate = useNavigate();
  const token = getToken();
  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState(null);
  const [formData, setFormData] = useState({ name: '', description: '', photo: null }); // Added 'photo' to formData
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!token) {
      navigate('/');
    } else {
      handleGetProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleGetProfile = async () => {
    try {
      const userData = await getProfile(token);
      setData(userData.data);
      setFormData({ name: userData.name, description: userData.description });
      setIsError(false);
    } catch (error) {
      setErrorMessage(error.response.data.message);
      setIsError(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedData = { ...formData };

      // Handle photo upload if formData contains 'photo'
      if (formData.photo) {
        const formDataWithPhoto = new FormData();
        formDataWithPhoto.append('photo', formData.photo);
        formDataWithPhoto.append('name', formData.name);
        formDataWithPhoto.append('description', formData.description);

        await updateProfile(token, formDataWithPhoto);
      } else {
        // If no photo is included, update only the profile data
        await updateProfile(token, updatedData);
      }

      handleGetProfile();
      setIsError(false);
    } catch (error) {
      setErrorMessage(error.response.data.message);
      setIsError(true);
    }
  };

  const handleLogout = () => {
    removeToken();
    navigate('/');
  };

  const handleChange = (e) => {
    if (e.target.type === 'file') {
      // If the target is a file input (photo upload), set 'photo' in formData
      setFormData({ ...formData, photo: e.target.files[0] });
    } else {
      // Otherwise, update other form fields
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  return {
    data,
    errorMessage,
    formData,
    isError,
    handleChange,
    handleSubmit,
    handleLogout,
    isEditing,
    toggleEditMode,
  };
};
