import React from "react";
import { PageWrapper, TitleWrapper } from "./wrappers.tsx";

export const Blogs = () => {

  return (
  <PageWrapper>
    <TitleWrapper>A community of digital nomads and colivings</TitleWrapper>
    <h3>What is a coliving?</h3>
    <p>
    Coliving is a housing arrangement where people live together in shared spaces, like a large house or apartment. It's kind of like having roommates but on a bigger scale. The idea is to create a sense of community and make city living more affordable. 
    </p>
    <p>
    People who choose coliving often share not just the living space but also common areas, like kitchens and living rooms. It's a trend that's become popular, especially among young professionals and those who want a more social way of living.
    </p>
    <h3>Skill Share</h3>
    <p>
      Skillshare is like a hidden treasure within a close-knit community. It's all about residents coming together to share their passions, talents, and expertise. Imagine a neighbor teaching you how to cook their family's secret recipe, another resident leading a yoga class in the communal area, or someone offering lessons in a musical instrument they love.
    </p>
    <p>
      It's not just about learning; it's about connecting on a personal level. You might discover shared hobbies, unlock hidden talents, or simply bond with your neighbors over shared interests. Skillshare in coliving adds depth to your living experience, making it more enriching and enjoyable. It's a chance to both teach and learn, fostering a sense of community that goes beyond the typical shared living space
    </p>
    <h3>Mastermind </h3>
    <p>
      A mastermind in a coliving context is like having your own personal board of advisors within your living community. It's a small, carefully curated group of like-minded individuals who come together to support each other's personal and professional growth.
    </p>
    <p>
      In a coliving mastermind, residents share their goals, challenges, and aspirations. They provide feedback, insights, and ideas to help each other overcome obstacles and achieve success. Whether it's brainstorming a new business idea, offering career advice, or simply providing emotional support, a coliving mastermind creates a safe and confidential space for residents to collaborate and grow together.
    </p>
    <p>
      It's a powerful concept that leverages the diversity and expertise of the community, fostering personal development, networking, and a strong sense of camaraderie among residents.
    </p>
    <h3>Family dinner</h3>
    <p>
    A family dinner is like a cozy, regular gathering where all residents sit down to share a meal. It's more than food; it's about bonding, laughter, and building a close-knit community. Imagine enjoying homemade dishes from different cultures while chatting with your extended coliving family. It's a heartwarming tradition that turns neighbors into friends.
    </p>
    <h3>Hikings</h3>
    <p>
    Picture exploring scenic trails and natural wonders together, from lush forests to breathtaking mountain views. It's not just about the physical activity but also about forging deeper connections, swapping stories, and making memories that'll last a lifetime. In coliving, hiking becomes a shared passion that brings residents closer and allows them to appreciate the beauty of nature as a community.
    </p>
    <h3>Board games</h3>
    <p>
    Board games are like a secret ingredient that adds a unique flavor to our shared experiences. It's those nights when we gather in the common area, each bringing our favorite games to the table. As we roll the dice, strategize, and compete, it feels like we're creating our own little world of excitement and connection.
    In those moments, it's not just about winning or losing; it's about the joy of spending quality time with roommates who become friends and friends who become like family. We share inside jokes, cheer for each other's victories, and even playfully argue over the rules. These board game nights are the heartwarming memories that make coliving truly special.
    </p>
  </PageWrapper>
  );
};