export const footerLinks = [
  {
    id:1,
    title: 'Coliving in Spain',
    url: 'spain',
    label: 'Spain'
  },
  {
    id:2,
    title: 'Coliving in France',
    url: '/france',
    label: 'France'
  },
  {
    id:3,
    title: 'Coliving in Italy',
    url: '/italy',
    label: 'Italy'
  },
  {
    id:4,
    title: 'Coliving in Portugal',
    url: '/portugal',
    label: 'Portugal'
  },
  { 
    id:5,
    title: 'Coliving in Germany',
    url: '/germany',
    label: 'Germany'
  }
];

export const footerFAQLinks = [
  {
    id:1,
    title: 'What is co-living?',
    url: '/what-is-coliving',
    label: 'What is co-living?'
  },
  {
    id:2,
    title: 'What are the benefits of co-living?',
    url: '/what-are-the-benefits-of-coliving',
    label: 'What are the benefits of co-living?'
  },
  {
    id:3,
    title: 'How does co-living differ from traditional renting?',
    url: '/how-does-coliving-differ-from-traditional-renting',
    label: 'How does co-living differ from traditional renting?'
  },
  {
    id:4,
    title: 'What amenities are typically included in a co-living space?',
    url: '/what-amenities-are-typically-included-in-a-coliving-space',
    label: 'What amenities are typically included in a co-living space?'
  },
  { 
    id:5,
    title: 'What is the duration of a co-living agreement? Can I rent on a short-term basis?',
    url: '/what-is-the-duration-of-a-coliving-agreement',
    label: 'What is the duration of a co-living agreement? Can I rent on a short-term basis?'
  },
  { 
    id:6,
    title: 'What is the community like in a co-living space? How is it maintained?',
    url: '/what-is-the-community-like-in-a-co-living',
    label: 'What is the community like in a co-living space? How is it maintained?'
  },
  { 
    id:7,
    title: 'What are the rules and policies regarding guests and visitors?',
    url: '/what-are-the-rules-and-policies-regarding-guests-and-visitors',
    label: 'What are the rules and policies regarding guests and visitors?'
  },
  // { 
  //   id:8,
  //   title: 'What is the duration of a co-living agreement? Can I rent on a short-term basis?',
  //   url: '/what-is-the-duration-of-a-coliving-agreement',
  //   label: 'What is the duration of a co-living agreement? Can I rent on a short-term basis?'
  // },
  // { 
  //   id:9,
  //   title: 'What is the duration of a co-living agreement? Can I rent on a short-term basis?',
  //   url: '/what-is-the-duration-of-a-coliving-agreement',
  //   label: 'What is the duration of a co-living agreement? Can I rent on a short-term basis?'
  // },
  // { 
  //   id:10,
  //   title: 'What is the duration of a co-living agreement? Can I rent on a short-term basis?',
  //   url: '/what-is-the-duration-of-a-coliving-agreement',
  //   label: 'What is the duration of a co-living agreement? Can I rent on a short-term basis?'
  // },
  // { 
  //   id:11,
  //   title: 'What is the duration of a co-living agreement? Can I rent on a short-term basis?',
  //   url: '/what-is-the-duration-of-a-coliving-agreement',
  //   label: 'What is the duration of a co-living agreement? Can I rent on a short-term basis?'
  // }
];