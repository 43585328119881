// components/Login/index.js
import React from 'react';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import { StyledButton, StyledForm } from '../SignUp/wrappers.ts';
import { LoginPageWrapper } from './wrappers.ts';
import { useAuthForm } from '../../hooks/useAuthForm.ts';

export const Login = () => {
  const initialFormState = {
    email: '',
    password: '',
  };
  const { formData, errorMessage, handleChange, handleSubmit, goTo, isError } = useAuthForm(
    initialFormState,
    'login'
  );

  return (
    <LoginPageWrapper>
      <StyledForm onSubmit={handleSubmit}>
        <h1>Login</h1>
        <TextField
          type="email"
          name="email"
          label="Email"
          value={formData.email}
          onChange={handleChange}
          variant="standard"
          error={isError}
        />
        <TextField
          type="password"
          name="password"
          label="Password"
          value={formData.password}
          onChange={handleChange}
          variant="standard"
          error={isError}
        />
        <StyledButton type="submit">Login</StyledButton>
        <Button color="secondary" onClick={() => goTo('/sign-up')}>
          Create a new user
        </Button>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      </StyledForm>
    </LoginPageWrapper>
  );
};