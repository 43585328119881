import React from "react";
import { ButtonWrapper } from "./wrappers.tsx";

interface ButtonProps {
    label: string;
    onClick: () => void;
    selected?: boolean;
}

const Button: React.FC<ButtonProps> = ({ label, onClick, selected }) => {
    return (
        <ButtonWrapper selected={selected} onClick={onClick}>
            {label}
        </ButtonWrapper>
    );
};

export default Button;
