import kitchen from './images/coliving-madrid-kitchen.JPG';
import backyard from './images/coliving-madrid-backyard.JPG';
import breakfast from './images/coliving-madrid-breakfast.jpg';
import frontdoor from './images/coliving-madrid-front.jpg';
import community2 from './images/coliving-madrid-community-2.JPG';
import community3 from './images/coliving-madrid-community-3.JPG';
import familyDinner from './images/coliving-madrid-digital-nomads.JPG';
import pet from './images/coliving-madrid-dog-2.jpg';
import petDaily from './images/coliving-madrid-dog-daily.JPG';
import pet2 from './images/coliving-madrid-dog.jpg';
import garden from './images/coliving-madrid-garden-2.JPG';
import hiking2 from './images/coliving-madrid-nature-horse-hiking.jpg';
import party from './images/coliving-madrid-night-out-madrid.jpg';
import roomLu from './images/coliving-madrid-room-lu.JPG';
import skillShare from './images/coliving-madrid-skill-share.jpg';
import views from './images/coliving-madrid-views.JPG';
import roomSofie from './images/room-sofie.JPG';
import roomGalaMarko from './images/room-gala-marko.JPG';
import roomGalaMarko2 from './images/room-gala-marko-2.JPG';
import roomIsabella from './images/room-isabella.JPG';
import toiletIsabella from './images/room-isabella-toilet.JPG';
import room2Lu from '../../../images/coliving-madrid/rooms/bedroomLu2.png';
import bathroomLu from '../../../images/coliving-madrid/rooms/bathroomLu.png';
import bathroomLu2 from '../../../images/coliving-madrid/rooms/bathroomlu2.png';
import hallLu from '../../../images/coliving-madrid/rooms/hall-Lu.png';
import dailyTasks from './images/daily-tasks.JPG';
import desktop from './images/desktop.JPG';
import diningRoom from './images/dining-room.JPG';
import mainDoor from './images/door.JPG';
import board from './images/entrance.JPG';
import games from './images/games.JPG';
import house from './images/house.JPG';
import kitchenTable from './images/kitchen-table.JPG';
import library from './images/library.JPG';
import standUpDesk from './images/Lu-stand-up-desk.jpg';
import sofa from './images/sofa.JPG';
import standUpDesk2 from './images/stand-up.JPG';
import trashCan from './images/trash-can.JPG';

export const images = [
  {
  img: frontdoor,
  title: 'Coliving Madrid frontdoor',
  },
  {
  img: backyard,
  title: 'Coliving Madrid backyard',
  },
  {
  img: kitchen,
  title: 'Coliving Madrid Kitchen',
  },
  {
  img: breakfast,
  title: 'Coliving Madrid breakfast',
  },
  {
  img: familyDinner,
  title: 'Coliving Madrid family dinner',
  },
  {
  img: pet,
  title: 'Coliving Madrid pets',
  },
  {
  img: petDaily,
  title: 'Coliving Madrid daily',
  },
  {
  img: pet2,
  title: 'Coliving Madrid rumba',
  },
  {
  img: garden,
  title: 'Coliving Madrid garden',
  },  
  {
  img: community3,
  title: 'Coliving Madrid community',
  },
  {
  img: community2,
  title: 'Coliving Madrid asado community 2',
  },
  {
  img: hiking2,
  title: 'Coliving Madrid hiking 2',
  },
  {
  img: party,
  title: 'Coliving Madrid party',
  },
  {
  img: skillShare,
  title: 'Coliving Madrid party skillshare',
  },
  {
  img: views,
  title: 'Coliving Madrid party views',
  },
  {
    img: dailyTasks,
    title: 'Daily tasks board'
  },
  {
    img: desktop,
    title: 'Desktop'
  },
  {
    img: diningRoom,
    title: 'Dining room'
  },
  {
    img: mainDoor,
    title: 'Main door'
  },
  {
    img: board,
    title: 'Hall of fame board'
  },
  {
    img: games,
    title: 'Games table'
  },
  {
    img: house,
    title: 'Main house picture'
  },
  {
    img: kitchenTable,
    title: 'Kitchen table'
  },
  {
    img: library,
    title: 'Library'
  },
  {
    img: standUpDesk,
    title: 'Stand up desktop'
  },
  {
    img: sofa,
    title: 'Couch'
  },
  {
    img: standUpDesk2,
    title: 'Stand up desktop'
  },
  {
    img: trashCan,
    title: 'Trash can'
  },
]

export const mockedRooms =[ {
    name: `Lu's room`,
    description: 'This room is the ultimate haven for digital nomads, designed to enhance your productivity and relaxation. Bathed in natural light from expansive windows, it provides the perfect setting for creative work and rejuvenation.',
    price: '60',
    image: [room2Lu, roomLu, bathroomLu, bathroomLu2, hallLu],
    alt: `Lu's room`
  },
  {
    name: `Alex's room`,
    description: `Alex's spacious room offers a cozy retreat with garden views, allowing you to pick fresh raisins in summer and bask in warm, soft light during winter—an idyllic setting for year-round comfort and tranquility.`,
    price: '40',
    image: [roomSofie],
    alt: `Alex's room`
  },  
  {
    name: `Sofie's room`,
    description: `Sofie's room, akin to Alex's, features garden views and balcony access for seasonal delights. Infused with a touch of feminism, it exudes a harmonious blend of comfort and empowerment, making it a welcoming space for all.`,
    price: '40',
    image: [roomSofie],
    alt: `Sofie's room`
  },  
  {
    name: `Isabella's room`,
    description: `Isabella's room, the largest in the house, features three bedrooms and a bamboo-crafted closet, with a double bathroom that elegantly frames the bedroom, making it an ideal haven for digital nomad influencers seeking both space and style.`,
    price: '30',
    image: [roomIsabella, toiletIsabella],
    alt: `Isabella's room`,
  },  
  {
    name: `Marko & Gala room`,
    description: `Marko and Gala's sun-soaked room is a cozy, warm sanctuary with an elegantly designed desktop, creating an inviting space that bathes in sunlight all day long. Perfect for work and relaxation.`,
    price: '50',
    image: [roomGalaMarko, roomGalaMarko2],
    alt: `Marko & Gala room`,
    discountThreeWeeks:'5%',
    discountMonth:'10%',
    discountTwoMonths:'20%'
  }
]