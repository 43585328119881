import styled from "@emotion/styled";

export const Container = styled.div`
  & p {
    text-align: justify;
  }
  & h2 {
    padding: 1em 0;
  }
  & h3 {
    padding: 0.5em 0;
  }
  & li {
    margin-left: 1em;
  }
  display: flex;
  flex-direction: column;
`